import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {NotificationService} from '../services/notification.service';
import {SessionService} from './session.service';

export const PermissionsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const notificationService = inject(NotificationService);
  const sessionService = inject(SessionService);
  const needed = route.data.permissions as string[];

  const allowed = needed.every(p => sessionService.permissions.permissions[p]);
  if (!allowed) {
    notificationService.messagesSubj.next('Acesso não permitido');
    router.createUrlTree(['/companies'])
  }
  return allowed;
}
