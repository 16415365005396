import { PERMISSIONS } from 'src/app/core/roles';

import {
 IItem,
 IItems,
 ECategoryType
} from './';

export const SIDEBAR: IItems = {
  top: {
    home: {
      title: 'Página Inicial',
      icon: 'home',
      exact: true,
      routes: [],
    },
    tickets: {
      title: 'Ações',
      icon: 'offline_bolt',
      permissions: [PERMISSIONS.ticket_r],
      routes: ['tickets'],
    },
    documents: {
      title: 'Documentos',
      icon: 'article',
      permissions: [PERMISSIONS.sick_note_r],
      routes: ['documents'],
      hover: true,
      children: [
        {
          title: 'CATEGORIAS',
          children: [
            {
              title: 'Atestados',
              permissions: [PERMISSIONS.sick_note_r],
              routes: ['documents', 'sick_notes'],
            },
          ],
        },
      ],
    },
    people: {
      title: 'Funcionários',
      icon: 'people',
      permissions: [PERMISSIONS.person_r],
      exact: true,
      routes: ['people'],
    },
    dashboards: {
      title: 'Relatórios',
      icon: 'pie_chart',
      permissions: [PERMISSIONS.dashboard_r],
      routes: ['dashboard'],
      hover: true,
      dashboard: true,
    },
    imports: {
      title: 'Envios',
      icon: 'upload',
      permissions: [PERMISSIONS.import_w],
      exact: true,
      routes: ['import'],
    },
  },
  bottom: {
    access: {
      title: 'Acesso',
      icon: 'key',
      permissions: [PERMISSIONS.user_r, PERMISSIONS.config_w],
      routes: ['sub', 'access'],
      children: [
        {
          title: 'Usuários',
          permissions: [PERMISSIONS.user_r],
          routes: ['sub', 'access', 'users'],
        },
        {
          title: 'Permissões',
          permissions: [PERMISSIONS.config_w], // Should be updated
          routes: ['sub', 'access', 'permissions'],
        }
      ],
    },
    config: {
      title: 'Configurações',
      icon: 'settings',
      permissions: [PERMISSIONS.config_w],
      routes: ['sub', 'config'],
      children: [
        {
          title: 'Gerais da empresa',
          routes: ['sub', 'config', 'general'],
          categoryType: ECategoryType.static,
          children: [
            {
              title: 'Departamentos',
              routes: ['sub', 'config', 'general', 'departments'],
            },
            {
              title: 'Sindicatos',
              routes: ['sub', 'config', 'general', 'labor-unions'],
            },
            {
              title: 'Filiais',
              routes: ['sub', 'config', 'general', 'locations'],
            },
            {
              title: 'Regionais',
              routes: ['sub', 'config', 'general', 'regional'],
            },
            {
              title: 'Motivos de recusa',
              routes: ['sub', 'config', 'general', 'refusal-reasons'],
            },
          ],
        },
        {
          title: 'Enviei.me',
          routes: ['sub', 'config', 'envieiMe'],
          categoryType: ECategoryType.static,
          children: [
            {
              title: 'Instruções gerais',
              routes: ['sub', 'config', 'envieiMe', 'general-instructions'],
            }
          ]
        },
        {
          title: 'Afastamento',
          routes: ['sub', 'config', 'absentment'],
          categoryType: ECategoryType.static,
          children: [
            {
              title: 'Tipos',
              routes: ['sub', 'config', 'absentment', 'types'],
            },
          ]
        },
        {
          title: 'Ocupacional',
          routes: ['sub', 'config', 'occupational'],
          categoryType: ECategoryType.static,
          children: [
            {
              title: 'Médicos PCMSO',
              routes: ['sub', 'config', 'occupational', 'doctor-management'],
            },
          ]
        }
      ],
    },
  },
};

export const OCCUPATIONAL: IItem = {
  title: 'Ocupacional',
  routes: ['documents', 'occupational'],
};

export const CHILDCARE_ASSISTENCE: IItem = {
  title: 'Reembolso',
  routes: ['documents', 'childcare-assistance'],
};

export const TOP_ITEMS: IItem[] = Object.values(SIDEBAR.top);
export const BOTTOM_ITEMS: IItem[] = Object.values(SIDEBAR.bottom);
