import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { ReadableErrorPipe } from '../core/error-messages';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { NoScrollDirective } from './directives/no-scroll.directive';
import { RecreateViewDirective } from './recreate-view.directive';
import { MaterialElevationDirective } from './utils/material-elevation-directive';
import {
  AgePipe,
  AmountPipe,
  CanEditPipe,
  CnpjFormatPipe,
  CompleteDifferenceDate,
  CpfFormatPipe,
  DateDiffPipe,
  FindPipe,
  FormatDate,
  FormatDocumentPipe,
  HasTravelTimePipePipe,
  IdentityFormatPipe,
  MapPipe,
  NameInitialsPipe,
  PendingStatus,
  PersonActivePipe,
  RefusalReasonCategoriesPipe,
  RouterBuilderPipe,
  SequencerPipe,
  SomePipe,
  StringPipe,
  ToHourPipe
} from './utils/pipes';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
  providers: [
    CompleteDifferenceDate
  ],
  declarations: [
    LoadingDialogComponent,
    MaterialElevationDirective,
    AgePipe,
    CpfFormatPipe,
    CnpjFormatPipe,
    IdentityFormatPipe,
    FormatDocumentPipe,
    NameInitialsPipe,
    SomePipe,
    FormatDate,
    MapPipe,
    DateDiffPipe,
    ReadableErrorPipe,
    FindPipe,
    StringPipe,
    RecreateViewDirective,
    NoScrollDirective,
    RouterBuilderPipe,
    SequencerPipe,
    ToHourPipe,
    HasTravelTimePipePipe,
    PersonActivePipe,
    CanEditPipe,
    AmountPipe,
    PendingStatus,
    RefusalReasonCategoriesPipe,
    CompleteDifferenceDate
  ],
  exports: [
    CommonModule,
    MaterialElevationDirective,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    AgePipe,
    CpfFormatPipe,
    CnpjFormatPipe,
    IdentityFormatPipe,
    NameInitialsPipe,
    SomePipe,
    FormatDate,
    MapPipe,
    DateDiffPipe,
    ReadableErrorPipe,
    FormatDocumentPipe,
    FindPipe,
    StringPipe,
    RecreateViewDirective,
    RouterBuilderPipe,
    SequencerPipe,
    ToHourPipe,
    HasTravelTimePipePipe,
    PersonActivePipe,
    CanEditPipe,
    AmountPipe,
    PendingStatus,
    RefusalReasonCategoriesPipe,
    CompleteDifferenceDate
  ]
})
export class SharedModule { }
