import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from '../core/session.service';

export const CompanyGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const sessionService = inject(SessionService);

  const companyId = route.paramMap.get('company_id');
  return sessionService.changeCurrentCompany(companyId).pipe(
    map((success) => success || router.createUrlTree(['login'], {queryParams: {returnUrl: state.url}}))
  );
}
