import {inject} from '@angular/core';
import {Location} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';

export const CanActivateChildGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const location = inject(Location);
  const dialog = inject(MatDialog);
  const router = inject(Router);

  if (dialog.openDialogs.length > 0) {
    const currentUrlTree = router.createUrlTree([], route);
    const currentUrl = currentUrlTree.toString();
    location.go(currentUrl);
    dialog.closeAll();
    return false;
  } else {
    return true;
  }
}
