import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../core/auth.service';


export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.isLoggedInIntoThePlatform().pipe(
    map(
      (isLoggedInIntoThePlatform) => {
        if (!isLoggedInIntoThePlatform) {
          return router.createUrlTree(['login'], { queryParams: { returnUrl: state.url }});
        }
        return true;
      }
    )
  );
}
