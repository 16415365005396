import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { SessionService } from "../core/session.service";
import { map, Observable } from "rxjs";


export const SamlRedirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const sessionService = inject(SessionService);
  const companyIdSAML = localStorage.getItem('SAML_LOGIN_COMPANY_ID');

  if (companyIdSAML) {
    localStorage.removeItem('SAML_LOGIN_COMPANY_ID');
    return sessionService.changeCurrentCompany(companyIdSAML).pipe(
      map((success) => {
        if (success) {
          return router.createUrlTree([`/c/${companyIdSAML}`]);
        }
        return true;
      })
    )
  }
  return true;
}
