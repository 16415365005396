<div class="profile" *ngIf="session.loggedInUser$ | async as user">
  <div *ngIf="!!user">
    <div class="d-flex justify-content-between gap-32">
      <div class="d-flex align-items-center gap-16">
        <div class="d-flex justify-content-center align-items-center circle">
          {{user.email | nameInitials}}
        </div>
        <div>
          <div>{{user.email}}</div>
        </div>
      </div>
      <button
        mat-icon-button
        mat-dialog-close
        class="small-circle-button"
        color="primary"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <!--
    <button
      mat-stroked-button
      mat-dialog-close
      class="w-100 mt-3"
      color="primary"
      (click)="settings()"
      *ngIf="isDev"
    >Configurações de usuário</button>
    -->
    <mat-divider class="w-100 mt-4 mb-2"></mat-divider>
    <button mat-button (click)="logout()">Sair da conta</button>
  </div>
</div>
