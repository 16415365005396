import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable, of} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';

import {CompanyService} from './company.service';
import {SessionService} from '../core/session.service';

export const DefaultCompanyGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const sessionService = inject(SessionService);
  const companyService = inject(CompanyService);

  return sessionService.currentCompanyId$.pipe(
    mergeMap((companyId) => {
      if (!!companyId) {
        return of(getUpdatedUrl(state.url, companyId));
      }

      return companyService.companies$.pipe(
        take(1),
        map(companies => {
          if (companies?.length === 1) {
            return getUpdatedUrl(state.url, companies[0].id);
          }
          return router.createUrlTree(['/companies'], { queryParams: { returnUrl: state.url }});
        })
      );
    })
  );
}

const getUpdatedUrl = (url: string, companyId: string): UrlTree => {
  const router = inject(Router);

  return router.parseUrl(url.replace('c/default', `/c/${companyId}`));
}
